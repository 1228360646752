@font-face {
  font-family: "Raleway";
  src: url(../../assets/fonts/Raleway/Raleway-VariableFont_wght.ttf);
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.css");
@font-face {
  font-family: Lato;
  src: url(../../assets/fonts/Lato/Lato-Regular.ttf);
}
@font-face {
  font-family: Lato;
  src: url(../../assets/fonts/Lato/Lato-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: Viga;
  src: url(../../assets/fonts/Viga/Viga-Regular.ttf);
}

/* .overallBar {
  position: relative;
} */

/* New Design */

/* Navbar */
.navbarSec {
  background: #044aac;
  padding: 10px 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.navbar-mobile {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.mobile-icon {
  display: none;
}

.mobileView {
  display: none;
}

.navlogo {
  width: 80px;
}

.activeNav {
  text-decoration: underline;
  color: #ffc619;
}

.navbarSec .navbar-mobile .searchbar {
  display: flex;
  align-items: center;
  background: white;
  padding: 12px 24px;
  border-radius: 40px;
  width: 350px;
  gap: 10px;
}

.navbarSec .navbar-mobile .searchbar .searchInput {
  background: transparent;
  outline: none;
  border: none;
  width: 100%;
}

.navigation-links {
  display: flex;
  gap: 10px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.navbar-buttons {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 700;
}

.upload {
  display: flex;
  align-items: center;
}

.navbar-buttons .login {
  border: 1px solid #ffffff;
  padding: 12px 24px;
  border-radius: 4px;
  background: transparent;
  color: white;
  font-weight: 700;
}
.navbar-buttons .signup {
  background: #ffc619;
  padding: 12px 24px;
  border-radius: 4px;
  border: none;
  font-weight: 700;
}

/* Hero Section */
.heroPic {
  width: 100%;
}

.heroResources img {
  width: 100%;
}

.heroSec {
  padding: 10px 40px;
  max-width: 1440px;
  margin: 0 auto;
  background: url(../../assets/Background\ pattern.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.heroSec .mainSec {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.heroSec .mainSec h1 {
  font-family: "Viga";
  font-size: 64px;
  font-weight: 400;
  color: black;
}
.heroSec .mainSec p {
  font-weight: 400;
  font-size: 24px;
}

.heroResources {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  margin: 10px 0px;
}

.bgHeroSec {
  position: relative;
  padding: 20px 0;
}

.bgHeroResources {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 50px;
  left: 20px;
  transform: rotate(-13deg);
}

.bgHeroResources img {
  width: 50px;
  height: 50px;
}

.bgHeroStudents {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 50px;
  right: 20px;
  transform: rotate(13deg);
}

.bgHeroStudents img {
  width: 50px;
  height: 50px;
}

.bgHero {
  width: 100%;
}

.getStarted-btn {
  background: #044aac;
  box-shadow: 0px 20px 35px rgba(4, 74, 172, 0.08);
  border: none;
  border-radius: 10px;
  color: #ffffff;
  font-family: "Lato";
  padding: 10px 24px;
}

.testi {
  max-width: 1291px;
  margin: 50px auto;
}

.testimonials h2 {
  font-family: Viga;
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  color: #044aac;
}

.testicards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.testicards .cardd {
  max-width: 412px;
  padding: 30px 25px;
  border-radius: 20px;
  box-shadow: 0px 0px 6px 0px #00000012;
  box-shadow: 0px 10px 15px -3px #0000001a;
}

.cardd h4 {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.10000000149011612px;
}

.details {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.details h5 {
  font-family: Viga;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.10000000149011612px;
  margin: 0;
}

.details h6 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.10000000149011612px;
}

.details img {
  width: 64px;
}

.aboutus {
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  gap: 50px;
  align-items: center;
  padding: 300px 20px;
}

.abouttext,
.aboutpic {
  flex: 1;
}

.aboutpic img {
  width: 100%;
}

.abouth1 {
  position: relative;
}

.abouttext h2 {
  font-family: Viga;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -0.06em;
  color: #044aac;
}

.abouttext h3 {
  margin: 40px 0;
  font-family: Lato;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
}

.underpic {
  position: absolute;
  bottom: 0;
}

.partners h1 {
  font-family: Viga;
  font-size: 48px;
  font-weight: 400;
  color: #044aac;
  letter-spacing: -0.06em;
  text-align: center;
}

.partpic {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1291px;
  margin: 0 auto;
  column-gap: 50px;
  row-gap: 20px;
}

.partpic img {
  max-width: 300px;
}

footer {
  background-image: url(../../assets/Rectangle\ 2689.png);
  background-position: center;
  background-size: cover;
  width: 100%;
  margin-top: 100px;
}

.footer {
  max-width: 1291px;
  padding: 50px 20px;
  margin: 0 auto;
  display: flex;
  gap: 50px;
  justify-content: space-evenly;
  align-items: center;
  color: white;
}

.firstfoot h5 {
  font-family: Lato;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.02em;
}

.firstfoot h6 {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.02em;
}

.secondfoot p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.02em;
}

.secondfoot {
  max-width: 389px;
}

.firstfoot {
  max-width: 251px;
}

.abouthome {
  background-image: url(/src/assets/Rectangle\ 6721.png);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: fit-content;
}

.planp {
  background: #f7f99726;
  padding: 100px 0;
}

#faq {
  background-color: #f7f7fb94;
  border-radius: 20px;
}

.FaQ {
  max-width: 900px;
  margin: 50px auto;
  padding: 50px 20px;
}

.faqcard {
  box-shadow: 0px 6px 16px 0px #4a3aff30;
  padding: 20px 50px;
  margin: 0 10px;
  border-radius: 15px;
}

.faqhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faqcard h5 {
  font-size: 22px;
  font-weight: 500;
  color: #170f49;
}

.faqcard h6 {
  color: #6f6c90;
  font-size: 18px;
  font-weight: 400;
}

.FaQ h3 {
  font-family: Viga;
  font-size: 48px;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: -0.06em;
  text-align: center;
  color: #044aac;
  margin-bottom: 70px;
}

.plantext {
  font-family: Raleway;
  font-size: 24px;
  font-weight: 400;
  color: #044aac;
  text-align: center;
}

.flycon1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffffcc;
  color: #545567;
  text-align: left;
  padding: 20px 20px;
  border-radius: 20px;
  gap: 30px;
  width: fit-content;
}

.sidebut1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 50px 50px 0 0;
}

.sidebut {
  margin: 50px 0 50px 50px;
  display: flex;
  align-items: flex-end;
}

.bot {
  width: 100px;
  height: 100px;
  position: fixed;
  z-index: 20;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
}

.bot img {
  width: 100%;
}

.personlaized {
  max-width: 1440px;
  margin: 10px auto;
}

.vectt {
  position: relative;
}

.vectt h1 {
  font-family: Viga;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -0.06em;
  text-align: center;
  color: #044aac;
}

.vectt img {
  position: absolute;
  left: 50%;
  bottom: -5px;
}

.personlaized h3 {
  font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  color: #696984;
  margin: 30px 0;
}

.coursebut {
  margin-top: 40px;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.coursecard {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin: 50px 10px;
}

.coursecardcc {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 50px 0;
}

.cardbody {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  color: #777795;
}

.students {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 40px;
  margin-top: -30px;
}

.students h5 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-family: Raleway;
}

.coursecard h6 {
  font-family: Raleway;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin: 10px 0;
  padding: 0;
}

#cardd {
  text-align: center;
  /* min-width: 250px; */
  border: none;
  border-radius: 14px;
  box-shadow: 0px 10px 60px 0px #262d7614;
}

.ccdd {
  color: #044aac;
  text-align: center;
  margin: 20px 0;
}

.ccd {
  color: #044aac;
}

.materials {
  padding: 20px;
  max-width: 1282px;
  margin: 100px auto;
}

.materials h3 {
  color: #696984;
  margin: 30px auto;
  max-width: 828px;
  font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}

#mater {
  border: none;
  text-align: center;
}

#mater:hover {
  box-shadow: 0px 50px 80px 0px #0000000d;
}
#mater:hover button {
  background-color: #044aac;
  color: white;
}

.cardbodyww {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  color: #696984;
}

.cardbody {
  text-align: left;
}

.matcard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.matbut {
  box-shadow: 0px 10px 60px 0px #262d7614;
}

.ball {
  width: 100px;
  height: 100px;
  background-color: #eb5757;
  /* border-radius: 100%; */
  margin: 0 auto;
  animation: scaleAnimation 2s infinite alternate;
}

.ball1 {
  width: 100px;
  height: 100px;
  background: #fbd033;
  /* border-radius: 100%; */
  margin: 0 auto;
  animation: scaleAnimation 2s infinite alternate;
}

.ball2 {
  width: 100px;
  height: 100px;
  background: #4a70f2;
  /* border-radius: 100%; */
  margin: 0 auto;
  animation: scaleAnimation 2s infinite alternate;
}

.ball3 {
  width: 100px;
  height: 100px;
  background: #5e8e3e;
  /* border-radius: 100%; */
  margin: 0 auto;
  animation: scaleAnimation 2s infinite alternate;
}

.ball img,
.ball1 img,
.ball2 img,
.ball3 img {
  width: 100%;
  height: 100%;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1); /* Original size */
  }
  100% {
    transform: scale(1.2); /* Scaled up by 20% */
  }
}

.tutorials {
  padding: 20px;
  max-width: 1179px;
  margin: 100px auto;
}

.tutorials h2 {
  color: #696984;
  margin: 30px auto;
  max-width: 912px;
  font-family: Raleway;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}

.ptutor {
  display: flex;
  gap: 50px;
  align-items: center;
  margin: 50px 0;
}

.ptutor img {
  width: 100%;
}

.gtutor {
  flex-direction: row-reverse;
}

.ptutor h3 {
  font-family: Viga;
  margin-bottom: 50px;
  color: #2d4dbf;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0.10000000149011612px;
}

.ptutortext {
  flex: 1;
  width: 50%;
}

.ptutorimg {
  flex: 1;
  width: 50%;
}

.ptutor h5 {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}

.newcomm {
  padding: 20px;
  max-width: 1282px;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.newcommpic {
  width: 50%;
  flex: 1;
}

.newcommpic img {
  width: 100%;
}

.newcommt {
  flex: 1;
}

.newcommt h1 {
  font-family: Viga;
  font-size: 48px;
  font-weight: 400;
  color: #044aac;
}

.newcommt h5 {
  font-family: Raleway;
  font-size: 24px;
  font-weight: 400;
  line-height: 43px;
  margin: 50px 0;
}

.newcommt {
  font-family: Raleway;
  font-size: 24px;
  font-weight: 400;
}

.ttee {
  position: relative;
}
.ttee img {
  position: absolute;
  top: -48px;
  right: 25%;
}

.newcommt a {
  color: #2563eb;
  font-family: poppins;
  font-size: 20px;
  font-weight: 700;
}

.evidence {
  background: #073676;
  margin: 100px 0;
}

.frameevi {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  padding: 20px;
  flex-wrap: wrap;
}

.fram1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fram1 h1 {
  font-size: 48px;
  font-weight: 800;
  color: white;
  margin: 10px 0;
}

.fram1 h3 {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
  color: #facc15;
}

.offers {
  display: flex;
  justify-content: center;
}

.offer0 {
  border-radius: 25px;
  max-width: 406px;
  height: 600px;
  text-align: center;
  background-color: white;
  box-shadow: 10px 0px 30px 0px #06020259;
  padding: 20px;
}

.offer1 {
  border-radius: 25px;
  max-width: 406px;
  height: 650px;
  text-align: center;
  background-color: white;
  box-shadow: 10px 0px 30px 0px #06020259;
  padding: 20px;
  margin-left: -20px;
}

li a {
  text-decoration: none;
  color: white;
}
li a:hover {
  text-decoration: none;
  color: white;
}

.offer2 {
  border-radius: 25px;
  max-width: 406px;
  height: 700px;
  text-align: center;
  padding: 20px;
  background-color: white;
  box-shadow: 10px 0px 30px 0px #06020259;
  margin-left: -20px;
}

.offer0 .head,
.offer2 .head,
.offer1 .head {
  background-image: url(../../assets/planBg.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.aihead {
  display: flex;
  align-items: center;
}

.aihead h3 {
  color: #044aac;
  font-family: Viga;
  font-size: 24px;
  font-weight: 400;
}

.searchai {
  border: 1px solid #bfc4d7;
  border-radius: 8px;
  /* gap: 10px; */
  justify-content: space-between;
  width: 100%;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.searchai input {
  max-width: 150px;
  border: none;
  background: transparent;
}

.sign {
  box-shadow: 0px 10px 30px 0px #0000000d;
  padding: 10px 20px;
}

.signcard {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.signcard .text h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.signcard h5 {
  margin: 10px 0;
  padding: 0;
  /* font-family: Inter; */
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

@media screen and (max-width: 500px) {
  #cardd {
    width: 150px;
  }

  .navbarSec {
    padding: 0 20px;
  }

  .heroSec {
    padding: 10px 20px;
  }

  .ptutor h3 {
    margin-bottom: 30px;
  }

  .flycon1 {
    padding: 10px;
    /* margin: 20px 0; */
  }
  .bot {
    width: 50px;
    height: 50px;
    right: 0;
  }
  .sidebut1 {
    margin: 50px 0px 0 0;
  }

  .sidebut {
    margin: 50px 0 50px 0px;
  }

  .vectt h1 {
    font-size: 34px;
  }

  .newcommt h1 {
    font-size: 34px;
  }

  .newcommt h5 {
    line-height: 33px;
  }

  .newcommt a {
    font-size: 18px;
  }

  .fram1 h1 {
    font-size: 34px;
    margin-bottom: 0;
  }

  .plantext {
    font-size: 20px;
  }

  .FaQ h3 {
    font-size: 34px;
    line-height: 50px;
  }

  .font-48 {
    font-size: 34px;
  }

  .fram1 h3 {
    font-size: 20px;
  }

  .ptutor {
    flex-direction: column;
    text-align: center;
  }

  .faqcard {
    padding: 20px;
  }

  .vectt img {
    left: 30%;
  }

  .newcomm {
    margin: 0;
    flex-direction: column-reverse;
    text-align: center;
  }

  .frameevi {
    gap: 30px;
  }

  .offers {
    gap: 30px;
    flex-direction: column;
  }

  .offer2 {
    margin: 0;
  }

  .ptutortext {
    width: 100%;
  }

  .ptutorimg {
    width: 100%;
  }

  .newcommpic {
    width: 100%;
  }

  .offer0,
  .offer2,
  .offer1 {
    height: auto;
  }

  .tutorials {
    margin: 0;
  }

  .materials,
  .personlaized {
    margin: 0;
  }

  .search img {
    display: none;
  }

  .search {
    padding: 10px 5px;
  }

  .personlaized {
    padding: 20px;
  }

  .testimonials {
    padding: 0 20px;
  }
  .testimonials h2 {
    font-size: 36px;
  }

  .aboutus {
    align-items: center;
    padding: 100px 20px;
    flex-direction: column-reverse;
    text-align: center;
  }

  .abouttext h3 {
    font-size: 18px;
    text-align: center;
  }

  .abouttext h2 {
    font-size: 36px;
  }

  .partners h1 {
    font-size: 36px;
  }

  .aboutus {
    align-items: center;
    padding: 0 20px;
    flex-direction: column-reverse;
    text-align: center;
  }

  .footer {
    padding: 40px 20px;
    flex-wrap: wrap;
  }

  .firstfoot {
    min-width: 100%;
  }

  .underpic {
    position: absolute;
    bottom: 0;
    left: 25%;
  }

  .faqcard h5 {
    font-size: 18px;
  }

  .planp {
    padding: 40px 0;
  }

  .navbar-mobile {
    display: none;
  }

  .mobile-icon {
    display: block;
  }

  .mobileView {
    position: fixed;
    top: 0;
    right: -60%;
    font-size: 14px;
    display: block;
    height: 100vh;
    width: 60%;
    background: #044aac;
    padding: 40px 30px;
    z-index: 100;
    transition: transform 0.3s ease-in-out;
  }

  .mobileView.open {
    transform: translateX(-100%);
  }

  .navigation-links {
    flex-direction: column;
    gap: 20px;
  }

  .mobileSignup a {
    padding: 6px 10px;
    border-radius: 4px;
    background: #ffc619;
    width: fit-content;
    color: black;
  }

  .heroResources {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
  }

  .heroSec .mainSec h1 {
    font-size: 24px;
  }

  .heroSec .mainSec p {
    font-size: 14px;
  }

  .bgHeroStudents img,
  .bgHeroResources img {
    width: 20px;
    height: 20px;
  }

  .bgHeroStudents h5,
  .bgHeroResources h5 {
    font-size: 12px;
  }

  .bgHeroStudents p,
  .bgHeroResources p {
    font-size: 12px;
  }

  .bgHeroStudents {
    top: 0px;
    right: 10px;
  }
  .bgHeroResources {
    top: 0px;
    left: 10px;
  }
}

@media (min-width: 500px) and (max-width: 1024px) {
  .bgHeroStudents,
  .bgHeroResources {
    top: 0px;
  }

  .navbar-mobile {
    display: none;
  }

  .mobile-icon {
    display: block;
  }

  .mobileView {
    position: fixed;
    top: 0;
    right: -60%;
    font-size: 14px;
    display: block;
    height: 100vh;
    width: 60%;
    background: #044aac;
    padding: 40px 30px;
    z-index: 100;
    transition: transform 0.3s ease-in-out;
  }

  .mobileView.open {
    transform: translateX(-100%);
  }

  .navigation-links {
    flex-direction: column;
    gap: 20px;
  }

  .mobileSignup a {
    padding: 6px 10px;
    border-radius: 4px;
    background: #ffc619;
    width: fit-content;
    color: black;
  }

  .heroResources {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }

  .heroSec .mainSec h1 {
    font-size: 40px;
  }

  .heroSec .mainSec p {
    font-size: 18px;
  }
}

.heroResources > div {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.heroResources > div:hover img {
  opacity: 0.6;
}

.heroResources > div:hover::after {
  content: "Click to Preview";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-weight: bold;
  z-index: 1;
}

.card {
  perspective: 1000px;
  cursor: pointer;
}

.card-inner {
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-back {
  transform: rotateY(180deg);
}
