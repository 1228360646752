.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 90% !important;
  height: 100% !important;
  margin-bottom: 20px;
}

.nav-but {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
