.org-highlight-card {
    border-radius: 15px;
    background: #fff;
    padding: 10px 20px;
  }
  .org-highlight-card-icon {
    background: #044aac;
    border-radius: 10px;
    padding: 15px;
  }
  .org-course-highlight {
    border-radius: 15px;
    background: #fff;
    padding: 20px;
  }