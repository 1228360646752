.calc-course {
  width: fit-content;
}
.calc-btn {
  border: none;
  padding: 17px 55px;
  background: #044aac;
  border-radius: 8px;
  color: #fff;
}
.total-unit {
  width: 180px;
  padding: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.35);
  box-shadow: 0px 4px 8px rgba(190, 190, 190, 0.2);
  border-radius: 10px;
  text-align: center;
}
.help-search {
  outline: none !important;
  padding: 19.0759px 25.4345px;
  background: #fafafa;
  border: 1.58966px solid #747474;
  border-radius: 12.7172px;
  width: 100%;
}
@media screen and (max-width: 479px) {
  .total-unit {
    width: 150px;
    padding: 10px;
    border: 0.5px solid rgba(0, 0, 0, 0.35);
    box-shadow: 0px 4px 8px rgba(190, 190, 190, 0.2);
    border-radius: 10px;
    text-align: center;
  }
  .cgpa-calc .viga-20-400 {
    font-size: 16px;
  }
  .cgpa-calc .poppins-20-400 {
    font-size: 16px;
  }
}
