@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

@font-face {
  font-family: Lato;
  src: url(../../assets/fonts/Lato/Lato-Regular.ttf);
}
@font-face {
  font-family: Lato;
  src: url(../../assets/fonts/Lato/Lato-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: Viga;
  src: url(../../assets/fonts/Viga/Viga-Regular.ttf);
}
.sidebar {
  background: #044aac;
  position: fixed;
  min-height: 100vh;
  width: 16%;
}
body::-webkit-scrollbar {
  width: 0.5rem;
}
body::-webkit-scrollbar-thumb {
  background-color: gainsboro; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid gainsboro; /* creates padding around scroll thumb */
}
body ::-webkit-scrollbar-button {
  background-color: gray;
}
.main-content {
  background: #ffff;
  margin-left: 16%;
  min-height: 100vh;
  position: absolute;
  width: 84%;
}
.sidebar-logo {
  padding: 5px 0px;
  text-align: center;
}
.sidebar-logo img {
  height: 120px;
}
.logo {
  padding: 24.5px 0px;
  text-align: center;
}
.nav-icon {
  padding: 0 20px;
}
.sidebar .nav-item {
  padding: 0 0px !important;
  height: 53px;
  align-items: center;
  color: #ffff;
}
.navactive {
  color: #044aac !important;
  background-color: #fff;
  width: 100%;
}
.navactive .nav-item {
  color: #044aac;
}
.navactive:hover {
  color: #044aac;
}
.inactive {
  color: #fff;
  background-color: transparent;
  width: 100%;
}
.inactive .nav-item:hover {
  color: #044aac;
}
.inactive:hover {
  color: #044aac;
  background-color: #fff;
}
.nav-item-active .nav-link {
  color: #fff !important;
}
.sidebar .nav-item {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  width: 100%;
}
.sidebar-tools {
  margin-top: 25%;
}
.sidebar-tools-inactive {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  width: 100%;
}
.sidebar-tools-inactive:hover {
  color: #044aac !important;
  background-color: #fff;
}
.sidebar-tools-inactive .nav-item:hover {
  color: #044aac !important;
}
.sidebar-tools-active .nav-item {
  color: #044aac !important;
}
.sidebar-tools-active {
  color: #044aac !important;
  background-color: #fff;
  width: 100%;
  align-items: center;
}
.content-nav {
  background: #044aac;
  padding: 0.5rem;
}
.blue-filled-btn {
  background: #044aac;
  border: none;
  border-radius: 15px;
  color: #ffffff;
  font-family: "viga";
  padding: 10px 24px;
}
.blue-filled-btn-5p {
  background: #044aac;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-family: "viga";
  padding: 10px 24px;
}
.blue-light-btn {
  background: #044aac;
  border: none;
  border-radius: 15px;
  color: #ffffff;
  font-family: "viga";
  padding: 10px 24px;
}

.grayed-btn {
  background: gray;
  border: none;
  border-radius: 15px;
  color: #ffffff;
  font-family: "viga";
  padding: 10px 24px;
}

.blue-outline-btn {
  background: #ffffff;
  border: 1px solid #044aac;
  border-radius: 15px;
  color: #044aac;
  font-family: "viga";
  padding: 10px 24px;
}

.grayed-outline-btn {
  background: gray;
  border: 1px solid gray;
  border-radius: 15px;
  color: white;
  font-family: "viga";
  padding: 10px 24px;
}

.blue-outline-btn:hover {
  background: #044aac;
  border: 1px solid #044aac;
  color: #fff;
}
.yes-btn {
  background: #044aac;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-family: "Lato";
}
.no-btn {
  background: #ffffff;
  border: 1px solid #044aac;
  border-radius: 10px;
  color: #044aac;
}
.buy-btn {
  background: #044aac;
  border: none;
  border-radius: 15px;
  color: #ffffff;
}
.green-btn-5p {
  background: #15bc7d;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 24px;
  display: flex;
  align-items: center;
}
.font-24-400 {
  font-family: "Viga";
  font-weight: 400;
  font-size: 24px;
}
/* Lato font styles */
.lato-8-400 {
  font-family: "Lato";
  font-size: 10px;
  font-weight: 300;
}
.lato-10-700 {
  font-family: "Lato";
  font-size: 10px;
  font-weight: 700;
}
.lato-12-300 {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 300;
}
.lato-12-400 {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 400;
}
.lato-12-700 {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 700;
}
.lato-14-400 {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
}
.lato-14-600 {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
}
.lato-16-300 {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 300;
}
.lato-16-400 {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  list-style: none;
}
.lato-16-500 {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 500;
  list-style: none;
}
.lato-16-600 {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 600;
}
.lato-18-700 {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 700;
}
.lato-20-300 {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 300;
}
.lato-20-400 {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 400;
}
.lato-20-500 {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 500;
}
.lato-20-600 {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 600;
}
.lato-20-700 {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 700;
}
.lato-24-400 {
  font-family: "Lato";
  font-size: 24px;
  font-weight: 400;
}
.lato-24-600 {
  font-family: "Lato";
  font-size: 24px;
  font-weight: 600;
}
.lato-24-700 {
  font-family: "Lato";
  font-size: 24px;
  font-weight: 700;
}
.lato-24-800 {
  font-family: "Lato";
  font-size: 24px;
  font-weight: 800;
}
.lato-32-400 {
  font-family: "Lato";
  font-size: 32px;
  font-weight: 400;
}
.lato-32-700 {
  font-family: "Lato";
  font-size: 32px;
  font-weight: 700;
}
.lato-36-400 {
  font-family: "Lato";
  font-size: 36px;
  font-weight: 400;
}
.lato-36-600 {
  font-family: "Lato";
  font-size: 36px;
  font-weight: 600;
}
.lato-36-700 {
  font-family: "Lato";
  font-size: 36px;
  font-weight: 700;
}
.lato-40-400 {
  font-family: "Lato";
  font-size: 40px;
  font-weight: 400;
}
.lato-40-600 {
  font-family: "Lato";
  font-size: 40px;
  font-weight: 600;
}
.lato-40-800 {
  font-family: "Lato";
  font-size: 40px;
  font-weight: 800;
}
.lato-48-600 {
  font-family: "Lato";
  font-size: 40px;
  font-weight: 600;
}
.lato-44-500 {
  font-family: "Lato";
  font-size: 44px;
  font-weight: 500;
}

.dashboard .alert {
  position: absolute;
  transition-delay: 2s;
  transition-timing-function: ease-out;
}
.hide {
  display: none;
  transition: 0.3s;
}
.text-blue {
  color: #044aac;
}
.text-green {
  color: #138f2e;
}
/* Viga font syles */
.viga-14-400 {
  font-family: "Viga";
  font-size: 14px;
  font-weight: 400;
}
.viga-16-400 {
  font-family: "Viga";
  font-size: 16px;
  font-weight: 400;
}
.viga-16-700 {
  font-family: "Viga";
  font-size: 16px;
  font-weight: 700;
}
.viga-18-400 {
  font-family: "Viga";
  font-size: 18px;
  font-weight: 400;
}
.viga-20-400 {
  font-family: "Viga";
  font-size: 20px;
  font-weight: 400;
}
.viga-24-400 {
  font-family: "Viga";
  font-size: 24px;
  font-weight: 400;
  margin: 10px 0;
}
.viga-32-400 {
  font-family: "Viga";
  font-size: 32px;
  font-weight: 400;
}
.viga-36-400 {
  font-family: "Viga";
  font-size: 36px;
  font-weight: 400;
}
.viga-40-400 {
  font-family: "Viga";
  font-size: 40px;
  font-weight: 400;
}
.viga-48-400 {
  font-family: "Viga";
  font-size: 48px;
  font-weight: 400;
}
.poppins-14-400 {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
}
.poppins-14-500 {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
}
.poppins-15-500 {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
}
.poppins-14-700 {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 700;
}
.poppins-20-400 {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
}
.poppins-20-500 {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
}
.poppins-36-600 {
  font-family: "Poppins";
  font-size: 36px;
  font-weight: 600;
}
.poppins-24-600 {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
}
.poppins-24-700 {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
}
.poppins-24-500 {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
}
.color-grey {
  color: #00000080;
}
.grey-btn {
  border-radius: 10px;
  background: #909090;
  color: #fff;
  border: none;
  padding: 10px;
}
.profile-grey {
  color: #909090;
  font-size: 20px;
  font-family: "Lato";
}
.profile-menu {
  border: none;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  width: 200px;
}
.profile-menu-img {
  height: 150px;
  border-radius: 10px;
}
.profile-pic-sm {
  height: 35px;
  width: 35px;
  border-radius: 250px;
  object-fit: cover;
}
.profile-pic-md {
  height: 48px;
  width: 48px;
  border-radius: 250px;
  object-fit: cover;
}
.profile-pic-lg {
  height: 250px;
  width: 250px;
  border-radius: 250px;
  border: 2px solid #044aac;
  background-size: cover;
  object-fit: cover;
}
.profile-info-link {
  color: #000000;
  text-decoration: none;
}
.text-goldShade {
  color: #b99511;
}
.text-pink {
  color: #f81000;
}
.text-purple {
  color: #6a26ca;
}
.text-yellowShade {
  color: #fbd03380;
}
.dashboard-header {
  width: 100% !important;
  /* background: linear-gradient(180deg, #2476fc 0%, #003778 100%); */
}

.dashboard-courses {
  background: #fff;
}
.dashboard-card {
  border: 1px solid #ffff;
}
.border-left-primary {
  border-left: 0.5rem solid #4e73df !important;
}
.border-left-warning {
  border-left: 0.5rem solid #f6c23e !important;
}
.border-left-goldShade {
  border-left: 0.5rem solid #b99511 !important;
  border: none;
  border-radius: 15px;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1)); */
  box-shadow: 5px 6px 6px 2px #e9ecef;
  height: 100px;
  transition: all 0.2s ease;
  cursor: pointer;
  width: 100%;
}
.border-left-pink {
  border-left: 0.5rem solid #f81000 !important;
  border: none;
  border-radius: 15px;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1)); */
  box-shadow: 5px 6px 6px 2px #e9ecef;
  height: 100px;
  transition: all 0.2s ease;
  cursor: pointer;
  width: 100%;
}
.border-left-purple {
  border-left: 0.5rem solid #6a26ca !important;
  border: none;
  border-radius: 15px;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1)); */
  box-shadow: 5px 6px 6px 2px #e9ecef;
  height: 100px;
  transition: all 0.2s ease;
  cursor: pointer;
  width: 100%;
}
.border-left-blueShade {
  border-left: 0.5rem solid #044aac !important;
  border: none;
  border-radius: 15px;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1)); */
  box-shadow: 5px 6px 6px 2px #e9ecef;
  height: 100px;
  transition: all 0.2s ease;
  cursor: pointer;
  width: 100%;
}
.border-left-goldShade:hover {
  box-shadow: 5px 6px 6px 2px #e9ecef;
  transform: scale(1.1);
}
.border-left-pink:hover {
  box-shadow: 5px 6px 6px 2px #e9ecef;
  transform: scale(1.1);
}
.border-left-purple:hover {
  box-shadow: 5px 6px 6px 2px #e9ecef;
  transform: scale(1.1);
}
.border-left-blueShade:hover {
  box-shadow: 5px 6px 6px 2px #e9ecef;
  transform: scale(1.1);
}
.border-left-blue {
  border-left: 0.5rem solid #044aac !important;
  border: none;
  border-radius: 15px;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1)); */
  height: 80px;
  color: #044aac;
}
.border-left-red {
  border-left: 0.5rem solid #ff302f !important;
  border: none;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  height: 80px;
  padding: 20px 30px;
  color: #ff302f;
}
.border-left-red:hover {
  background: #ff302f;
  color: #fff;
}
.border-left-grey {
  border-left: 0.5rem solid #909090 !important;
  border: none;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  height: 80px;
  padding: 20px 30px;
  color: #909090;
}
.border-left-grey:hover {
  background: #909090;
  color: #fff;
}
.border-left-blue:hover {
  background: #044aac !important;
  color: #fff !important;
}
.border-left-blue p:hover {
  color: #fff;
}
.category-card:hover {
  color: #fff !important;
}
.course-container {
  background: #fff;
  /* display: grid; */
}
.category-card {
  height: 80px;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.course-divider {
  padding: 1px 0px;
}
.user-name {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
}
.header-image {
  width: 48px;
  height: 48px;
  border-radius: 10px !important;
}
.page-id {
  font-family: "Lato";
  font-weight: 600;
  font-size: 16px;
  color: rgba(38, 50, 56, 0.5);
}
.search-bar {
  border: 1px solid #044aac;
  border-radius: 30px;
  width: 400px;
}
.search-bar-study {
  border: 1px solid #044aac;
  border-radius: 30px;
  width: 100%;
  height: fit-content;
  outline: none;
}
.search-bar-4px {
  border: 4px solid #f8f8f8;
  border-radius: 30px;
  width: 400px;
}
.search-bar-sm {
  border-radius: 30px;
  border: 1px solid #f8f8f8;
  width: 100%;
}
.search-bar-grey {
  background: #fafafa;
  border-radius: 8px;
  width: 400px;
  padding: 12px 16px;
  border: none;
}
.font-24-700 {
  font-family: "Lato";
  font-weight: 700;
  font-size: 24px;
}
.font-20-700 {
  font-family: "Lato";
  font-weight: 700;
  font-size: 20px;
}

.course-card {
  background: #fbd033;
  border-radius: 15px;
  width: 220px;
  height: 120px;
  margin: 0px 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}
.course-card2 {
  background: #044aac;
  border-radius: 15px;
  width: 220px;
  height: 120px;
  margin: 0px 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}
.courses-card {
  background: #044aac;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  width: 140px;
  height: 80px;
}
.course-content {
  border-radius: 15px;
  width: 220px;
  height: 120px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}
.course-content-2 {
  border-radius: 15px;
  width: 140px;
  height: 80px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}
.tutorial-card {
  width: fit-content;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(190, 190, 190, 0.2);
  border-radius: 10px;
}
.tutorial-card-container .card-img-overlay {
  /* padding: 6rem 12rem; */
  display: flex;
}
.tutorial-card-container .attend-btn {
  background: rgba(4, 74, 172, 0.75);
  border: none;
  border-radius: 100px;
  color: #ffff;
  margin: auto;
}
.calendar-component {
  box-shadow: 0px 8px 16px rgba(190, 190, 190, 0.2);
  border-radius: 10px;
}
.activities-container {
  width: 100%;
}
.activites-card {
  width: fit-content;
  height: fit-content;
  border-radius: 15px;
  box-shadow: 0px 8px 16px rgba(190, 190, 190, 0.4);
  background: #ffffff;
}
.tutor-activites-card {
  border-radius: 15px;
  box-shadow: 0px 8px 16px rgba(190, 190, 190, 0.9);
  width: 100%;
  border: none;
}
.activity-icon {
  padding-right: 20px;
  height: 40px;
  width: 40px;
}
.activity-link {
  color: #000000;
}
.activity-link:hover {
  color: #044aac;
}
.notification-container {
  background: #fff;
}
.notification-card {
  width: 707px;
  height: 138px;
  background: #ffffff;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.plan-preview {
  width: 100%;
  height: 140px;
  background: #ffffff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.notification-cris {
  width: 56px;
  height: 56px;
  background: #eaeefd;
  border-radius: 50%;
}
.message-avatar {
  margin: auto;
}
.nav-container {
  width: 100%;
  height: 62px;
  background: #ffffff;
}
.plan-text {
  font-family: "Viga";
  font-weight: 400;
  font-size: 24px;
  color: #044aac;
  text-align: center;
}
.font-12-800 {
  font-family: "Lato";
  font-weight: 800;
  font-size: 12px;
}
.font-12-500 {
  font-family: "Lato";
  font-weight: 500;
  font-size: 12px;
}
.font-12-400 {
  font-family: "Lato";
  font-weight: 400;
  font-size: 12px;
}
.font-20-400 {
  font-family: "Lato";
  font-weight: 400;
  font-size: 20px;
}
.font-24-600 {
  font-family: "Lato";
  font-weight: 600;
  font-size: 24px;
}
.font-16-400 {
  font-family: "Lato";
  font-weight: 400;
  font-size: 16px;
}
.font-16-500 {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
}
.font-16-600 {
  font-family: "Lato";
  font-weight: 600;
  font-size: 16px;
}
.font-10-400 {
  font-family: "Lato";
  font-weight: 400;
  font-size: 10px;
}
.font-10-300 {
  font-family: "Lato";
  font-weight: 300;
  font-size: 10px;
  color: #9e9e9e;
}
.blue-40-400 {
  font-family: "Lato";
  font-weight: 400;
  font-size: 40px;
  color: #044aac;
}
.blue-40-800 {
  font-family: "Lato";
  font-weight: 800;
  font-size: 40px;
  color: #044aac;
}
.blue-32-600 {
  font-family: "Lato";
  font-weight: 600;
  font-size: 32px;
  color: #044aac;
}
.left-main {
  width: 60%;
}
.right-main {
  width: 35%;
}
.indicator-blue {
  color: rgba(45, 77, 191, 1);
}
.indicator-orange {
  color: rgba(255, 48, 47, 1);
}
.indicator-grey {
  color: rgba(196, 196, 196, 1);
}
.course-table {
  min-height: 100vh;
}
.all-course {
  border: none;
  background: none;
  color: #fff;
}
.my-course {
  border: none;
  background: none;
}
.footnote-grey {
  color: #a1a1a1;
}
.mytutorial-container {
  background: #fff;
}
.tutorial-container {
  background: #fff;
  min-height: 100vh;
}
.tutorial-card-container {
  background: #ffff;
  color: #000000;
  border-radius: 25px;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.01)); */
  border: 1px solid #e2e2e2;
  /* width: fit-content !important; */
}
.tutorial-fee {
  border-radius: 10px;
  color: rgba(51, 51, 51, 0.75);
  font-size: 10px;
  font-weight: 700;
}
.status-upcoming {
  /* background: #e8e8e8; */
  border-radius: 10px;
  font-size: 10px;
  font-weight: 700;
  color: #044aac;
}
.status-missed {
  /* background: #e8e8e8; */
  border-radius: 10px;
  font-size: 10px;
  font-weight: 700;
  color: #f81000;
}
.status-attended {
  /* background: #e8e8e8; */
  border-radius: 10px;
  font-size: 10px;
  font-weight: 700;
  color: #909090;
}
.status-recorded {
  /* background: #e8e8e8; */
  border-radius: 10px;
  font-size: 10px;
  font-weight: 700;
  color: #044aac;
}
.status-requested {
  border-radius: 10px;
  font-size: 10px;
  font-weight: 700;
  color: #33b469;
}
.community-container {
  background: #fff;
  min-height: 120vh;
}
.bg-grey {
  background: #cccfd4;
}
.chat-bg {
  background: rgba(4, 74, 172, 0.15);
}

.header-cards {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
}
.header-cards:hover {
  background: rgba(4, 74, 172, 0.25);
}
.header-cards:active {
  background: rgba(4, 74, 172, 0.25);
}
.community-header-image {
  height: 60px;
  width: 60px;
}
.community-header-name {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  padding: 0px 15px;
  color: rgba(0, 0, 0, 1);
}
.community-header-time {
  font-family: "Inter";
  font-weight: 600;
  font-size: 10px;
  color: #fbd033;
}
.community-link {
  text-decoration: none;
}
.community-link button {
  border: none;
  padding: 20px 10px;
  width: 100%;
  background-color: transparent;
  color: #fff;
}
.community-link-sm {
  width: 100%;
  background-color: transparent;
  color: #044aac;
}
.header-message-count {
  background: #044aac;
  font-family: "Lato";
  font-weight: 500;
  font-size: 8px;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
}
.chat-active {
  background: rgba(4, 74, 172, 0.25);
}
.wallet-container {
  background: #fff;
  min-height: 120vh;
}
.wallet-op {
  border-radius: 20px;
  /* padding: 20px; */
  width: 100px;
  height: 100px;
  display: flex;
  cursor: pointer;
}
.wallet-op-1 {
  background: #f0d36a;
}
.wallet-op-2 {
  background: #044aac;
}
.wallet-op-3 {
  background: #f0d36a;
}
.wallet-op-4 {
  background: #22b07d;
}
.wallet-activity {
  display: flex;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(190, 190, 190, 0.2);
}
.wallet-activity-icon-1 {
  background: #3686f7;
  width: 50px;
  height: 50px;
  border-radius: 20px;
  display: flex;
  padding: 10px;
}
.wallet-activity-icon-2 {
  background: #f0d36a;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 20px;
  display: flex;
}
.wallet-activity-icon-2 img {
  height: 25px;
  width: 25px;
}
.wallet-activity-icon-3 {
  background: #044aac;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 20px;
  display: flex;
}
.wallet-activity-icon-3 img {
  height: 25px;
  width: 25px;
}
/* .wallet-header {
  top: 10%;
  z-index: 1020;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 4px 8px rgba(190, 190, 190, 0.2);
  overflow: hidden;
} */
.balance-card {
  background: #044aac;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  height: 200px;
  width: 100%;
  padding: 30px;
}
.balance-card-text {
  font-family: "Viga";
  font-weight: 400;
  font-size: 24px;
  color: #fff;
}
.balance-number {
  font-family: "Lato";
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.more-coins-btn {
  font-family: "Lato";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #044aac;
  background: #fcfcfc;
  border-radius: 15px;
  padding: 5px 10px;
}
.basic-card {
  width: 300px;
  height: 550px;
  background: rgba(4, 74, 172, 0.25);
  border-radius: 15px;
  margin: 100px 20px 0px 20px;
}
.silver-card {
  width: 300px;
  height: 550px;
  background: rgba(4, 74, 172, 0.5);
  border-radius: 15px;
  margin: 50px 20px 20px 20px;
}
.premium-card {
  width: 300px;
  height: 550px;
  background: rgba(4, 74, 172, 0.75);
  border-radius: 15px;
  margin: 0px 20px 0px 20px;
}
.sub-card-header {
  background-image: url(../../assets/subBg.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.basic-card-modal {
  width: 300px;
  height: 550px;
  background: rgba(4, 74, 172, 0.25);
  border-radius: 15px;
}
.silver-card-modal {
  width: 300px;
  height: 550px;
  background: rgba(4, 74, 172, 0.5);
  border-radius: 15px;
}
.basic-card-sub {
  width: 300px;
  height: 550px;
  background: rgba(4, 74, 172, 0.25);
  border-radius: 15px;
}
.silver-card-sub {
  width: 300px;
  height: 550px;
  background: rgba(4, 74, 172, 0.5);
  border-radius: 15px;
}
.flashQuizzes .card-header {
  background: rgba(4, 74, 172, 0.25);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 25px 25px 0px 0px;
  color: #fff;
  padding: 20px 30px;
  border: none;
}
.flashQuizzes .card {
  border: none;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* border-radius: 25px; */
  cursor: pointer;
}
.flashcard-btn {
  background: #044aac;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  border: none;
  padding: 10px 20px;
  margin-top: 30px;
}
.flashcard-icon {
  height: 150px;
  width: 100%;
}
.chat-header {
  background: #044aac !important;
  color: #fff;
}
.received-message {
  width: 236px;
  height: 60px;
  background: rgba(4, 74, 172, 0.25);
  border-radius: 5px;
}
.sent-message {
  width: 236px;
  height: 60px;
  background: rgba(251, 208, 51, 0.25);
  border-radius: 5px;
}
.chat-message-time {
  font-family: "Inter";
  font-weight: 400;
  color: #011627;
}
.my-course-notename {
  background: #001435;
  color: #fff;
  width: 70%;
  padding: 10px;
}
.request-tutorial .login-input {
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid #e8e8e8;
  outline: none;
}
.help-header {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
}
.help-container {
  background: #fff;
}
.faq-container {
  background: rgba(217, 217, 217, 0.29) !important;
  border-radius: 10px;
}
.view-btn-active {
  font-family: "Viga";
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  border: 1px solid #044aac;
  background: #044aac;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
}
.course-btn-active {
  font-family: "Viga";
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  border: 1px solid #044aac;
  background: #044aac;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 20px;
}
.course-btn-inactive {
  font-family: "Viga";
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.016em;
  /* color: rgba(0, 0, 0, 0.25); */
  border: 1px solid #044aac;
  background: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 20px;
}
.course-nav {
  display: flex;
  justify-content: space-between;
}
.dashboard-courses {
  display: flex;
  justify-content: space-between;
}

.course-navactive {
  font-family: "Lato";
  font-weight: 800;
  font-size: 16px;
  color: #000000;
}
.course-inactive {
  font-family: "Lato";
  font-weight: 800;
  font-size: 16px;
  color: #ffffff;
}
.resource-card {
  transition: all 0.3s ease;
  border-radius: 10px;
  background-color: rgba(228, 223, 223, 0.822);
  padding: 7px;
}
.resource-card:hover {
  box-shadow: 5px 6px 6px 2px #e9ecef;
  /* transform: scale(0.8); */
}
.tutorial-active {
  font-family: "Viga";
  font-weight: 400;
  font-size: 24px;
  color: black;
}
.tutorial-inactive {
  font-family: "Viga";
  font-weight: 400;
  font-size: 24px;
  color: #00000040;
}
.allTut-active {
  font-family: "Lato";
  font-weight: 700;
  font-size: 16px;
  color: #333333;
}
.allTut-inactive {
  font-family: "Lato";
  font-weight: 700;
  font-size: 16px;
  color: rgba(51, 51, 51, 0.5);
}
.resource-active {
  color: #fff;
  font-family: "Lato";
  font-weight: 700;
  font-size: 16px;
  background: #044aac;
  border: 1px solid #044aac;
  border-radius: 5px;
  padding: 8px 10px;
  margin-right: 18px;
}
.resource-inactive {
  color: #000000;
  font-family: "Lato";
  font-weight: 700;
  font-size: 16px;
  border: 1px solid #044aac;
  background: none;
  padding: 8px 10px;
  border-radius: 5px;
  margin-right: 18px;
}
.resource-inactive:hover {
  background: #044aac;
  color: #fff;
}
.pay-btn {
  border: none;
  background: #044aac;
  border-radius: 8px;
  padding: 13px 37px;
  color: #fff;
}
.readmore-pay {
  border: none;
  border-radius: 2px;
  color: #ffff;
  height: 40px;
  width: 100px;
  background: #044aac;
}
.readmore-paid {
  border: none;
  background: rgba(51, 51, 51, 0.25);
  border-radius: 2px;
  color: #ffff;
  height: 40px;
  width: 100px;
}
.readmore-cancel {
  border: 1px solid #044aac;
  border-radius: 2px;
  color: #044aac;
  background: #fff;
  height: 40px;
  width: 100px;
}
.readmore-cancel:hover {
  background: #044aac;
  color: #ffff;
}
.readmore-haeader {
  background: rgba(4, 74, 172, 0.75);
  color: #ffff;
}
.readmore-haeader .btn-close {
  color: #ffff;
}
.course-info-container {
  justify-content: space-between;
}
.resource-container {
  background: #d9d9d9;
  min-height: 100vh;
}
.resource-inner {
  background: #ffff;
  min-height: 100vh;
}
.course-header {
  /* position: sticky;
  top: 15%;
  z-index: 1020; */
  /* background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 4px 8px rgba(190, 190, 190, 0.2); */
  overflow: hidden;
}
.resource-topnav {
  background: #fff;
  /* position: sticky;
  top: 11%;
  z-index: 1020; */
}
.feeds-topnav {
  background: #fff;
}
.tutorial-top-nav {
  background: #fff;
  /* position: sticky;
  top: 10%;
  z-index: 1020; */
}
.tutorial-subnav {
  background: #fff;
  /* position: sticky;
  top: 20%; */
}
.live-tutorial-header {
  /* position: sticky;
  top: 11%;
  z-index: 1020; */
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 4px 8px rgba(190, 190, 190, 0.2);
  overflow: hidden;
}
/* Roboto Fonts */
.roboto-8-400 {
  font-family: "Roboto";
  font-size: 8px;
  font-weight: 400;
}
.roboto-14-700 {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 700;
}
.roboto-12-400 {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
}
.roboto-10-400 {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
}
.roboto-16-400 {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
}
.roboto-24-700 {
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 700;
  line-height: 25px;
}
.all-courses-card {
  display: flex;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(90, 88, 88, 0.2);
  border-radius: 10px;
  align-items: center;
  border: 1px solid #fbd03380;
}
.my-course-card {
  display: flex;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(190, 190, 190, 0.2);
  border-radius: 10px;
  align-items: center;
  border: 1px solid #fbd03380;
}
.all-courses {
  justify-content: space-between;
}
.all-course-card {
  width: 175px;
  background-color: #fff;
}
.all-courses-img img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
}
.my-courses-img img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
}
/* .flipping-card {
  perspective: 1000px;
  cursor: pointer;
} */
.flashQuizzes .card-body {
  background: #fcfcfc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 25px 25px;
  min-height: 200px;
  text-align: center;
}
/* .card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform 0.5s ease-in-out;
}

.card-front {
  z-index: 2;
}

.card-back {
  transform: rotateY(180deg);
}

.flipped .card-front {
  transform: rotateY(180deg);
}

.flipped .card-back {
  transform: rotateY(0deg);
} */
.flashQuizzes .card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-back {
  transform: rotateY(180deg);
}
.notification-wrapper {
  border-radius: 12px;
  background: #f2f6ff;
}
.notification-header {
  background: #d9d9d9;
  width: fit-content;
  padding: 5px;
  border-radius: 6px;
  align-items: center;
}
.notification-active {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  padding: 10px 20px;
  background: #044aac;
  border: none;
  border-radius: 6px;
  transition: 0.3s ease-out;
}
.notification-inactive {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  border: none;
  padding: 10px 20px;
  background: none;
}
.setup-active {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  color: black;
  padding: 5px 10px;
  background: white;
  border: none;
  border-radius: 6px;
  transition: 0.3s ease-out;
}
.setup-inactive {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  color: gray;
  padding: 5px 10px;
  background: none;
  border: none;
  border-radius: 6px;
  transition: 0.3s ease-out;
}
.notification-date {
  font-size: 14px;
  font-style: "lato";
}
.notification-divider {
  padding: 1px 0px;
}
.notification-menu {
  width: 600px;
  border: none;
}
.notification-menu .card-header {
  background: #fff;
}
.notification-icon {
  background: #044aac;
  border-radius: 50px;
  height: fit-content;
  width: fit-content;
  padding: 10px;
}
.notification-cell {
  border-bottom: 1px solid grey;
}
.course-checkbox {
  color: #044aac;
  padding: 5px;
}
.quiz-header {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(190, 190, 190, 0.2);
  border-radius: 10px;
}
.timer-box {
  background: #044aac;
  box-shadow: 0px 2px 4px rgba(190, 190, 190, 0.2);
  border-radius: 10px;
  color: #fff;
  padding: 10px;
}
.time-counter {
  background: #fff;
  color: #000000;
  border-radius: 50%;
}
.quiz-option {
  background: rgba(47, 128, 237, 0.2);
  border: 1px solid #006cec;
  border-radius: 10px;
}
.correct-option {
  background: rgba(51, 180, 105, 0.2);
  border: 1px solid #33b469;
}
.incorrect-option {
  background: rgba(237, 58, 58, 0.2);
  border: 1px solid #ed3a3a;
}
.chat-input {
  width: 60%;
  box-shadow: 0px 8px 16px rgba(190, 190, 190, 5);
}
.bot-chatbar {
  /* background: #ffffff;
  border: 1px solid #8babd8;
  border-radius: 10px;
  padding: 8px 61px 8px 16px; */
  border: none;
  outline: none;
  width: 80%;
}
.inputbar-container {
  margin-left: 16%;
  /* margin-bottom: 2rem; */
  /* background-color: #fff; */
  /* box-shadow: 0px 8px 16px rgba(190, 190, 190, 1.2); */
  border: none;
}
.chatbot-send {
  color: #8babd8;
}
.chat-bot-mic {
  color: #8babd8;
}
.chat-input {
  background: #ffffff;
  border: 1px solid #8babd8;
  border-radius: 10px;
  padding: 8px 61px 8px 16px;
}
.chatbot-container {
  border-radius: 10px;
  background: rgba(4, 74, 172, 0.05);
  min-height: 200px;
  margin-bottom: 10%;
}
.clearchat-btn {
  outline: none;
  background: #e8e8e8;
  border-radius: 20px;
  border: none;
  padding: 10px;
}
.chat-suggestion {
  background: #e2e2e2;
}
.chatbot-message {
  color: #fff;
}
.chat-response {
  background: #fff;
  border-radius: 5px;
  padding: 0px 5px;
  width: fit-content;
  color: #000000;
}
.copy-button {
  color: #006cec;
}
.sidebar-sm {
  position: fixed;
  /* top: ; */
  right: -200px; /* Start the sidebar off-screen */
  width: 200px;
  height: fit-content;
  background-color: #fff;
  transition: right 0.3s ease;
  padding: 10px 10px;
}
.sidebar-sm.open {
  right: 0; /* Slide the sidebar into view */
}
.carousel-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
form {
  position: relative;
}

.slides2 {
  position: relative;
  width: 600px;
  height: 500px;
  margin-bottom: 20px;
  overflow: hidden;
}

.slides1 {
  position: relative;
  width: 600px;
  height: 400px;
  margin-bottom: 20px;
  overflow: hidden;
}

.slide {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide.active {
  display: block;
}

.slide h2 {
  margin-bottom: 10px;
}

.indicators {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 10px;
  cursor: pointer;
}

.indicator.active {
  background: #044aac;
}

.next-button {
  padding: 10px 20px;
  background-color: #044aac;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
}

.next-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.submit-button {
  padding: 10px 20px;
  background-color: #044aac;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
}
.preloader-container {
  height: 100vh;
}
.preloader-container .spinner-grow {
  font-size: 40px !important;
}
/* Countdown */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  z-index: 2;
  text-align: center;
}
.userTutorials .card {
  background: transparent;
}
.tutorial-pay-btn {
  border-radius: 4px;
  border: 1px solid #fff;
  background: #044aac;
  padding: 8px 30px;
  color: #fff;
}
.tutorial-join-btn {
  border-radius: 4px;
  border: 1px solid #044aac;
  background: #fff;
  padding: 8px 20px;
  color: #044aac;
}
.tutorial-join-disabled {
  border-radius: 4px;
  border: none;
  background: gray;
  padding: 8px 20px;
  color: #fff;
}
.search-results {
  background: #006cec !important;
  border-radius: 10px;
  margin-top: 5px;
  position: absolute;
  width: 400px;
}
.search-results .show-results {
  display: block;
  padding: 10px;
}
.search-results .hide-results {
  display: none;
  padding: 10px;
}

/* Toogle switch */
/* Add this CSS to your stylesheet */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sub-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Off color */
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 15px;
}

.sub-slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
}

input:checked + .sub-slider {
  background-color: #044aac;
}

input:checked + .sub-slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

.success-modal {
  padding: 0 !important;
}
.quiz-textbox {
  padding: 20px 30px;
  width: 100%;
  border: none;
  background: rgb(239, 241, 243);
  border-radius: 10px;
  outline: none;
  color: black;
  font-size: 18px;
}
.admin-quiz-option {
  width: 100%;
  border: none;
  background: none;
  border-radius: 10px;
  outline: none;
  color: black;
}
.quiz-option-container {
  padding: 15px 20px;
  width: 90%;
  border: none;
  background: rgb(238, 240, 241);
  border-radius: 10px;
  color: #0000;
}
.quiz-fixed-column {
  max-height: 80vh;
  overflow-y: auto;
}

.cgpa-preview {
  border: 2px solid #e0e6ed;
  box-shadow: 0px 7px 14px 0px #d3dae2;
  padding: 20px;
  border-radius: 11px;
  width: 50%;
}

.cgpa-preview h3 {
  color: #3b3f5c;
  font-family: Viga;
  font-size: 24px;
  font-weight: 400;
  line-height: 38.4px;
  letter-spacing: 0.14000000059604645px;
}

.cgpa-preview p {
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.14000000059604645px;
  color: #3b3f5c;
}
.user-resource-head {
  background: #044aac;
  color: #fff;
  padding: 20px 40px !important;
}

.rating-overlay {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 16px;
  font-size: 14px;
}
.coin-wallet-balance {
  background: #044aac;
  border: none;
  color: #fff;
  width: 60%;
  border-radius: 10px;
}
.coin-wallet-rates {
  background: #044aac;
  border: none;
  color: #fff;
  width: 30%;
  border-radius: 10px;
}
.coin-wallet-input {
  border: 1px solid #044aac;
  outline: none;
  padding: 10px 20px;
  width: 100%;
  border-radius: 30px;
}
.coin-conversion-arrow {
  color: #044aac;
  text-align: center;
  margin: auto;
  font-size: 45px;
}
.coin-btn-active {
  border: 2px solid #044aac;
  color: #fff;
  background: #044aac;
  padding: 10px 20px;
  border-radius: 20px;
}
.coin-btn-inactive {
  border: 2px solid #044aac;
  background: #fff;
  color: #044aac;
  padding: 10px 20px;
  border-radius: 20px;
}

.referral-cgpa {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 50px;
}

.referral-container {
  width: 50%;
}
.referal-input-bar {
  border: 0;
  outline: none;
  width: 100%;
  background: transparent;
}
.referal-search {
  border-radius: 10px;
  border: 1px solid gray;
  padding: 10px 20px;
  display: flex;
}

/* Scholarship Styles */
.scholarship-container {
  border-radius: 12px;
  background: #c7d3f1;
}

/* Feeds styles */
.feedCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 50px;
}

.course-preview {
  width: 75%;
}
.suggested-resources {
  width: 25%;
}

.my-org-card {
  border: 1px solid #fbd033;
  padding: 8px;
  border-radius: 8px;
}
.other-org-card {
  border: 1px solid #00000033;
  padding: 8px;
  border-radius: 8px;
}
.other-org-btn {
  border: 1px solid #00000033;
  width: 100%;
  border-radius: 8px;
  outline: none;
  background: none;
  padding: 5px 0px;
}
.other-org-btn:hover {
  background: black;
  color: white;
}

/* For medium Devices */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .sidebar {
    display: none;
  }
  .main-content {
    width: 100%;
    margin-left: 0%;
    background: #fff;
    padding: 0rem !important;
  }
  .logo img {
    height: 40px;
  }
  .search-bar {
    width: 200px;
  }
  .page-id {
    font-size: 8px;
  }
  .search-bar-grey {
    width: 150px;
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
  }
  .mobile-nav {
    background: #fff;
    border-radius: 25px 25px 0px 0px;
    border-top: 1px solid darkblue;
  }
  .mobile-active {
    /* border-top: 2px solid #fff; */
    width: 100%;
    color: #044aac;
  }
  .mobile-inactive {
    /* border-top: 2px solid #044aac; */
    color: #787878;
  }
  .all-course-card {
    background-color: #fff;
    margin-right: 20px;
    width: 150px;
    padding: 0px 10px;
  }
  .AllCoursesContainer .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: break-spaces;
    padding: 10px;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;
  }
  .referral-container {
    width: 100%;
  }
  .cgpa-preview {
    width: 100%;
  }
  .feeds-topnav {
    top: 15%;
    padding-top: 10px;
    text-wrap: nowrap;
    overflow-x: scroll;
  }
  .course-preview {
    width: 100%;
  }
  .suggested-resources {
    width: 100%;
  }
  .slides1 {
    width: 400px;
  }
  .slides2 {
    width: 400px;
  }
}

/* Small tablets */
@media screen and (min-width: 501px) and (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .main-content {
    width: 100%;
    margin-left: 0%;
    background: #fff;
    padding: 0rem !important;
  }
  .logo img {
    height: 40px;
  }
  .search-bar {
    width: 200px;
  }
  .page-id {
    font-size: 8px;
  }
  .search-bar-grey {
    width: 150px;
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
  }
  .mobile-nav {
    background: #fff;
    border-radius: 25px 25px 0px 0px;
    border-top: 1px solid darkblue;
  }
  .mobile-active {
    /* border-top: 2px solid #fff; */
    width: 100%;
    color: #044aac;
  }
  .mobile-inactive {
    /* border-top: 2px solid #044aac; */
    color: #787878;
  }

  .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 10px;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;
  }
  .left-main {
    width: 100%;
  }
  .right-main {
    width: 100%;
  }
  .border-left-goldShade {
    padding: 0px 10px !important;
  }
  .border-left-pink {
    padding: 0px 30px !important;
  }
  .border-left-purple {
    padding: 0px 40px !important;
  }
  .border-left-blueShade {
    padding: 0px 30px !important;
  }
  .tutorial-card {
    width: 100%;
  }
  .dashboard-header .mobile-side-links {
    width: 200px;
    inset: 0px -20px auto auto !important;
  }
  .mobile-side-links {
    transition: all 0.5s ease-out;
  }
  .profile-img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
  }
  .referral-container {
    width: 100%;
  }
  .cgpa-preview {
    width: 100%;
  }
  .referral-cgpa {
    flex-direction: column;
    margin-bottom: 50px;
  }

  /* Feeds styles */
  .feeds-topnav {
    top: 15%;
    padding-top: 10px;
    text-wrap: nowrap;
    overflow-x: scroll;
  }
  .feedCards {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 100px;
  }

  .course-preview {
    width: 100%;
  }
  .suggested-resources {
    width: 100%;
  }
  .setup-left {
    display: none;
  }
}

/* For small devices */
@media screen and (max-width: 500px) {
  .sidebar {
    display: none;
  }
  .main-content {
    width: 100%;
    margin-left: 0%;
    background: #fff;
    padding: 0rem !important;
  }
  .logo img {
    height: 40px;
  }
  .logo {
    padding: 10px 0px;
  }
  .search-bar {
    width: 200px;
  }
  .page-id {
    font-size: 8px;
  }
  .search-bar-grey {
    width: 120px;
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
  }
  .course-container {
    padding: 0px 0px 20px 0px !important;
  }
  .font-24-600-white {
    font-size: 16px;
    color: #fff;
  }
  /* .dashboard-header {
    background: linear-gradient(180deg, #2476fc 0%, #003778 100%);
    height: 100px;
  } */
  .dashboard-header .user-name {
    display: none;
  }
  .left-main {
    width: 100%;
    background: transparent;
  }
  .left-main .font-24-600 {
    font-size: 20px;
    color: rgba(4, 74, 172, 1);
  }
  .right-main {
    width: 100%;
  }
  .mytutorial-container {
    background: #fff !important;
  }
  .activities-container {
    background: #fff !important;
    padding: 0.8rem !important;
  }
  .activites-card {
    width: 100%;
    height: fit-content;
  }
  .notification-container {
    background: #fff;
    margin-bottom: 100px;
  }
  .notification-container .font-24-600 {
    color: #044aac;
  }
  .notification-card {
    width: 100%;
    height: 138px;
  }
  .plan-preview {
    width: 100%;
    height: fit-content;
    background: #ffffff;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
    border: 2px solid #e0e6ed;
  }
  .course-card {
    width: fit-content;
    height: fit-content;
    margin: 0px 8px;
  }
  .course-card2 {
    width: fit-content;
    height: fit-content;
    margin: 0px 8px;
  }
  .course-content {
    height: 60px;
    width: 100px;
    margin-left: 5px !important;
    padding: 5px 0px !important;
  }
  .course-content .font-24-600 {
    font-size: 12px;
  }
  .font-16-600 {
    font-size: 10px;
  }
  .user-welcome {
    font-size: 10px !important;
  }
  .font-24-700 {
    font-size: 12px;
    padding-left: 2px !important;
  }
  .course-table .font-24-700 {
    font-size: 20px;
  }
  .course-table .lato-20-500 {
    font-size: 16px;
  }
  .font-24-700-bg {
    font-size: 20px;
  }
  .tutorial-card-img {
    width: 100%;
  }
  .content-nav {
    margin: 10px 10px !important;
  }
  .lato-16-400 {
    font-size: 12px;
  }
  .font-20-400 {
    font-size: 12px;
  }
  .lato-24-600 {
    font-size: 16px;
  }
  .date {
    display: none;
  }
  .user-welcome {
    color: #044aac;
  }
  .mobile-nav {
    background: #044aac;
    border-radius: 30px;
    padding: 5px;
  }
  .mobile-active {
    /* border-top: 2px solid #fff; */
    width: 100%;
    color: #044aac;
    background: #fff;
    border-radius: 30px;
    padding: 10px;
  }
  .mobile-inactive {
    /* border-top: 2px solid #044aac; */
    color: #fff;
    padding: 10px;
  }
  .course-header {
    top: 14%;
  }
  .resource-topnav {
    top: 15%;
    padding-top: 10px;
    text-wrap: nowrap;
    overflow-x: scroll;
  }
  .activities-container {
    width: 100%;
  }
  .wallet-img img {
    height: 40px;
    width: 40px;
  }
  .balance-card-text {
    font-size: 20px;
  }
  .balance-number {
    font-size: 32px;
  }
  .more-coins-btn {
    font-size: 12px;
  }
  .balance-card {
    padding: 10px;
  }
  .userDashboard .viga-24-400 {
    font-size: 16px;
  }
  .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;
    width: 100%;
  }
  .resource-active {
    padding: 5px 8px;
  }
  .resource-inactive {
    padding: 5px 8px;
  }
  .dashboard-header .mobile-side-links {
    width: 200px;
    inset: 0px -20px auto auto !important;
  }
  .notification-menu {
    width: 350px;
  }
  .notification-menu .viga-20-400 {
    font-size: 15px;
  }
  .notification-menu .lato-14-400 {
    font-size: 12px;
  }
  .notification-menu .lato-20-400 {
    font-size: 15px;
  }
  .notification-icon img {
    width: 40px;
    height: 40px;
  }
  .notification-date {
    font-size: 10px;
  }
  .notification-active {
    font-size: 15px;
  }
  .notification-inactive {
    font-size: 15px;
  }
  .setup-active {
    font-size: 15px;
  }
  .setup-inactive {
    font-size: 15px;
  }
  .notification-body .lato-20-400 {
    font-size: 12px;
    margin-bottom: 2px;
  }
  .notification-body .viga-20-400 {
    font-size: 15px;
  }
  .nav-icon {
    padding: 0 10px;
  }
  .border-left-goldShade {
    padding: 0px 10px !important;
    height: 80px !important;
  }
  .border-left-pink {
    padding: 0px 30px !important;
    height: 80px !important;
  }
  .border-left-purple {
    padding: 0px 40px !important;
    height: 80px !important;
  }
  .border-left-blueShade {
    padding: 0px 30px !important;
    height: 80px !important;
  }
  .tutorial-card {
    width: 100%;
  }
  .mobile-side-links {
    transition: all 0.5s ease-out;
  }
  .profile-img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
  }
  .profile-grey {
    font-size: 17px;
  }
  .profile-name {
    font-size: 14px;
    font-family: "Lato";
    font-weight: 600;
  }
  .my-course-card {
    width: 100%;
    align-items: start;
  }
  .my-course-img {
    height: 100px;
  }
  .all-course-card {
    background-color: #fff;
    margin-right: 20px;
    width: 150px;
    padding: 0px 10px;
  }
  .AllCoursesContainer .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: break-spaces;
    padding: 10px;
    -webkit-overflow-scrolling: touch;
  }
  .category-card .lato-18-700 {
    font-size: 12px;
  }
  .course-categories .viga-20-400 {
    font-size: 16px;
  }
  .course-categories .viga-24-400 {
    font-size: 20px;
  }
  .dashboard-courses .lato-20-400 {
    font-size: 10px;
  }
  .dashboard-courses .viga-20-400 {
    font-size: 14px;
  }
  .course-info {
    margin: 0px 10px;
    font-size: 12px;
  }
  .course-info .lato-20-700 {
    font-size: 12px;
  }
  .course-info .lato-20-300 {
    font-size: 12px;
  }
  .subscription-modal .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    /* padding: 10px; */
    -webkit-overflow-scrolling: touch;
    justify-content: left !important;
  }
  .subscription-modal li {
    font-size: 13px;
  }
  .subscription-modal .basic-card-modal {
    height: 420px;
  }
  .subscription-modal .silver-card-modal {
    height: 420px;
  }
  .basic-card-sub {
    height: 420px !important;
    width: 100%;
  }
  .silver-card-sub {
    height: 420px !important;
    width: 100%;
  }
  .inputbar-container {
    margin-left: 0%;
    margin-bottom: 18%;
  }
  .chat-input {
    width: 90%;
  }
  .slides1 {
    position: relative;
    width: 350px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  .slides2 {
    position: relative;
    width: 350px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  .ai-icon img {
    height: 60px;
  }
  .aivibes .lato-20-400 {
    font-size: 12px;
  }
  .aivibes .viga-20-400 {
    font-size: 15px;
  }
  .course-name .viga-20-400 {
    font-size: 15px;
  }
  .chatbot-container {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    min-height: 200px;
    margin-bottom: 38%;
  }
  .wallet-container {
    min-height: 100vh;
  }
  .wallet-op {
    border-radius: 20px;
    /* padding: 20px; */
    width: 60px;
    height: 60px;
  }
  .wallet-op img {
    height: 30px;
    width: 30px;
  }
  .wallet-operations .lato-24-400 {
    font-size: 14px;
  }
  .wallet-operations .viga-24-400 {
    font-size: 16px;
  }
  .wallet-header .lato-24-400 {
    font-size: 15px;
  }
  .balance-number {
    font-size: 24px;
  }
  .wallet-activity .lato-24-400 {
    font-size: 16px;
  }
  .wallet-activity .lato-24-700 {
    font-size: 16px;
  }
  .profile-pic-lg {
    height: 150px;
    width: 150px;
  }
  .my-courses-img img {
    height: 80px;
    width: 80px;
  }
  .all-courses-img img {
    height: 80px;
    width: 80px;
  }
  .all-courses-card {
    align-items: start;
  }
  .resource-card {
    transition: all 0.3s ease;
    border-radius: 10px;
    background-color: rgba(228, 223, 223, 0.822);
    padding: 7px;
    height: fit-content;
  }
  .tutorDashboard .lato-24-700 {
    font-size: 16px;
  }
  .cgpa-preview h3 {
    font-size: 16px;
  }
  .cgpa-preview p {
    font-size: 14px;
  }
  .flashQuizzes .card {
    border: none;
    background: transparent;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* border-radius: 25px; */
    cursor: pointer;
    height: 300px;
  }
  .card-front,
  .card-back {
    position: absolute;
    width: 100%;
    height: 300px;
    backface-visibility: hidden;
  }
  .flashQuizzes .card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .flipped .card-inner {
    transform: rotateY(180deg);
  }
  .coin-wallet-balance {
    width: 100%;
  }
  .coin-wallet-rates {
    width: 100%;
  }
  .coin-conversion-arrow {
    font-size: 32px;
  }
  .referral-container {
    width: 100%;
  }
  .cgpa-preview {
    width: 100%;
  }
  .referral-cgpa {
    flex-direction: column;
    margin-bottom: 50px;
  }
  .referral-cgpa {
    margin-bottom: 50px;
  }
  .referal-search {
    padding: 5px;
    font-size: 12px;
  }
  .referral-container .lato-20-400 {
    font-size: 14px;
  }

  /* Feeds styles */
  .feeds-topnav {
    top: 15%;
    padding-top: 10px;
    text-wrap: nowrap;
    overflow-x: scroll;
  }
  .feedCards {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 100px;
  }

  .course-preview {
    width: 100%;
  }
  .suggested-resources {
    width: 100%;
  }
  .view-btn-active {
    font-size: 12px;
  }
  .setup-left {
    display: none;
  }
}
