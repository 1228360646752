.tutor-sidebar {
  background: #0a3066;
  position: fixed;
  min-height: 100vh;
  width: 16%;
}
.tutor-sidebar .navactive {
  color: #044aac !important;
  background-color: #fff;
  width: 100%;
}
.tutor-sidebar .navactive .nav-item {
  color: #044aac;
}
.tutor-sidebar .navactive:hover {
  color: #044aac;
}
.tutor-sidebar .inactive {
  color: #fff;
  background-color: transparent;
  width: 100%;
}
.tutor-sidebar .inactive .nav-item:hover {
  color: #044aac;
}
.tutor-sidebar .inactive:hover {
  color: #044aac;
  background-color: #fff;
}
.tutor-sidebar .nav-item-active .nav-link {
  color: #fff !important;
}
.tutor-sidebar .nav-item {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  width: 100%;
  padding: 0 10px !important;
  height: 53px;
  align-items: center;
  color: #ffff;
}
.tutor-sidebar-tools{
  margin-top: 80%;
}
.tutor-balance-card {
  background: rgba(10, 48, 102, 0.75);
  border-radius: 10px;
  color: #ffff;
  text-align: center;
  height: 100%;
}
.tutor-acct-details {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.tutor-wallet-activity {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #0a3066;
}
.tutor-wallet-activity #Withdraw {
  color: #eb5757;
}
.tutor-wallet-activity #Payment {
  color: #20b15a;
}
.schedule-tutorial .login-input {
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid #e8e8e8;
  outline: none;
}
.tutor-activity-box {
  border-bottom: 1px solid grey;
}
.tutor-accdit {
  width: 200px;
}
.web-page-iframe{
  height: 100%;
  width: 100%;
}
.iframe-container{
  height: 800px;
  width: 100%;
}
@media screen and (max-width: 479px) {
  .tutor-sidebar {
    display: none;
  }
  .tutor-header .user-name {
    display: none;
  }
  .tutorTutorial .lato-32-700 {
    font-size: 20px;
  }
  .tutorTutorial .lato-40-800 {
    font-size: 24px;
  }
  .tutorTutorial .lato-20-400 {
    font-size: 15px;
  }
  .tutorTutorial .lato-20-600 {
    font-size: 15px;
  }
  .tutorTutorial .lato-20-700 {
    font-size: 15px;
  }
  .tutorTutorial .lato-24-400 {
    font-size: 15px;
  }
  .tutorTutorial .lato-24-500 {
    font-size: 15px;
  }
  .tutorTutorial .lato-24-800 {
    font-size: 15px;
  }
  .tutorTutorial .lato-14-400 {
    font-size: 10px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .tutor-sidebar {
    display: none;
  }
  .main-content {
    width: 100%;
    margin-left: 0%;
    background: #fff;
    padding: 0rem !important;
  }
}
@media screen and (min-device-width: 601px) and (max-device-width: 906px) {
  .tutor-sidebar {
    display: none;
  }
  .main-content {
    width: 100%;
    margin-left: 0%;
    background: #fff;
    padding: 0rem !important;
  }
}
