@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

.container-bg {
  background: linear-gradient(
    223.58deg,
    #044aac 62.61%,
    #efd98b 77.23%,
    #7396c7 94.04%
  );
  min-height: 100vh;
  width: 100%;
}
.container-gradient {
  background: linear-gradient(
    223.58deg,
    #044aac 62.61%,
    #efd98b 77.23%,
    #7396c7 94.04%
  );
  min-height: 100vh;
}
.auth-white {
  background: white;
  height: 100vh;
  overflow-y: auto;
}
.auth-blue {
  background: linear-gradient(
    223.58deg,
    #044aac 62.61%,
    #efd98b 77.23%,
    #7396c7 94.04%
  );

  height: 100vh;
}
.col-sm-5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}
.col-md-5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}
.signup-left {
  background-image: url(../../assets/img/SignUpLeft.png);
  background-position: center;
  background-size: cover;
}
.login-left {
  background-image: url(../../assets/img/loginLeft.jpg);
  background-position: center;
  background-size: cover;
}
.create-left {
  background-image: url(../../assets/img/loginLeft.jpg);
  background-position: center;
  background-size: cover;
  overflow: hidden;
}
.reset-left {
  background-image: url(../../assets/img/resetLeft.jpg);
  background-position: center;
  background-size: cover;
}
.reset-success {
  background-image: url(../../assets/img/resetSuccess.jpg);
  background-position: center;
  background-size: cover;
}
.setup-left {
  background-position: center;
  background-size: cover;
  background: linear-gradient(
      227.18deg,
      rgba(252, 252, 252, 0.54) -12.14%,
      rgba(252, 252, 252, 0) 46.87%,
      rgba(49, 45, 45, 0.42) 68.99%,
      rgba(54, 38, 38, 0.49) 82.93%
    ),
    url(../../assets/img/setupBg.png);

  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.setupTutor-left {
  background-position: center;
  background-size: cover;
  background: linear-gradient(
      227.18deg,
      rgba(252, 252, 252, 0.54) -12.14%,
      rgba(252, 252, 252, 0) 46.87%,
      rgba(49, 45, 45, 0.42) 68.99%,
      rgba(54, 38, 38, 0.49) 82.93%
    ),
    url(../../assets/img/tutorBg.jpg);

  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.usersuccess-left {
  background-image: url(../../assets/img/userSuccess.jpg);
  background-position: center;
  background-size: cover;
}
.tutorsuccess-left {
  background-image: url(../../assets/img/tutorSuccess.jpg);
  background-position: center;
  background-size: cover;
}
/* .sm-3-white {
  background: linear-gradient(
      227.18deg,
      rgba(252, 252, 252, 0.54) -12.14%,
      rgba(252, 252, 252, 0) 46.87%,
      rgba(49, 45, 45, 0.42) 68.99%,
      rgba(54, 38, 38, 0.49) 82.93%
    ),
    url(../../assets/img/setupBg.png);

  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
} */
.form-container {
  width: 500px;
}
.form-container-lg {
  width: 600px;
}
.form-container-xl {
  width: 800px;
}
.logo-h3 {
  font-weight: 700;
  font-size: 32px;
}
.text-grey {
  color: #a1a1a1;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.blue-h3 {
  color: #044aac;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.blue-span {
  color: #044aac;
}
.blue-h5 {
  color: #044aac;
  font-size: 14px;
  font-weight: 700;
}
.login-option {
  background: #f5f5f5;
  border-radius: 15px;
  width: fit-content;
  padding: 30px;
  justify-content: center;
}
.login-option img {
  height: 75px;
  width: 70px;
  object-position: center;
}
.login-option:hover {
  background: rgba(232, 173, 0, 0.3);
}
a {
  text-decoration: none;
}
.h3-grey {
  color: #525252;
  font-size: 28px;
  font-weight: 600;
}
.span-grey {
  color: #a1a1a1;
}
.span-black-400 {
  color: #3d3d3d;
  font-size: 16px;
}
.black-500 {
  color: #525252;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-32 {
  font-size: 32px;
  color: white;
  font-weight: 700;
}
.font-32-500 {
  font-size: 32px;
  color: #ffffff;
  font-weight: 500;
}
.span-white-500 {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
}
.span-white-600 {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
  font-size: 12px;
}
.poppins-20-600 {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
}
.poppins-16-400 {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
}
.login-form .form-select {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
.login-form input {
  /* width: 100%; */
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid #e8e8e8;
  outline: none;
}
.login-form label {
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #525252;
}
.login-label {
  width: 50%;
  text-align: left;
}
.login-input {
  width: 100%;
}
.submit-btn {
  /* width: 464px; */
  width: 100%;
  height: 40px;
  left: 0px;
  top: 0px;
  color: white;
  background: #044aac;
  border: none;
  border-radius: 6px;
}

.checked-btn {
  width: 100%;
  height: 40px;
  left: 0px;
  top: 0px;
  color: white;
  background: gray;
  border: none;
  border-radius: 6px;
}

.google-btn {
  width: 100%;
  height: 40px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}
.google-btn-small {
  height: 40px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}
.input-group {
  box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  width: fit-content;
  height: 40px;
}
.btn-blue {
  background: #044aac;
  color: white;
  border-radius: 30px;
  font-size: 14px;
  border: none;
}
.btn-white {
  background: white;
  color: #044aac;
  font-size: 14px;
  border-radius: 30px;
  border: none;
}
.grey-28 {
  font-weight: 600;
  font-size: 28px;
}
.carousel-indicators button {
  border-radius: 50%;
  height: 16px !important;
  width: 16px !important;
}
/* .carousel-indicators [data-bs-target] {
    background: #044AAC;
} */
.text-input {
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid #7a7979;
  background: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  color: #7a7979;
  width: 100%;
}
.text-input-icon {
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid #ffffff;
  border-radius: 0%;
}
.white-btn {
  background: #ffffff;
  border-radius: 6px;
  border: none;
  color: #044aac;
  width: 100%;
  height: 40px;
}
.btn-blue:hover {
  background-color: #044aac !important;
  color: #ffffff !important;
}
.carousel-select {
  background: transparent;
  border: none;
  border-bottom: 1px solid #7a7979;
  border-radius: 0;
  color: #7a7979;
  width: 100%;
}
.account-btn-group {
  background: #fff;
  border-radius: 30px;
  box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.1);
  width: fit-content;
}
.congratulations svg {
  height: 150px !important;
}
.c_pass {
  cursor: pointer;
  border-bottom: 1px solid #e8e8e8;
  font-size: 1.1rem;
}
.upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-panel {
  width: 100% !important;
  height: 130px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #666;
  background-color: #f9f9f9;
  border: 1px solid #e4e7ec;
}

.upload-icon {
  font-size: 24px;
  margin-bottom: 8px;
}

.upload-details {
  font-size: 12px;
  color: #999;
}

/* Mobile Styles */
@media screen and (max-width: 479px) {
  .login-option {
    padding: 10px;
  }
  .auth-white {
    background: none;
    height: fit-content;
  }
  .auth-blue {
    min-height: 100vh;
    /* height: fit-content; */
  }
  .sm-3-white {
    background: none;
    height: fit-content;
  }
  .form-container {
    width: 100%;
  }
  .form-container-lg {
    width: 100%;
  }
  /* .logo {
    margin: auto;
  } */
  .blue-h5 {
    font-size: 10px;
  }
  .auth-white .blue-h3 {
    text-align: center;
  }
  .login-redirect {
    text-align: center;
  }
  .form-container {
    padding-top: 5px !important;
  }
  .form-container-lg {
    padding-top: 5px !important;
  }
  /* .login-form label {
    color: #ffffff;
    border-bottom: 3px solid #ffffff;
    padding-right: 15px;
  }
  .login-form input {
    background: none;
    color: #ffffff;
    border-bottom: 3px solid #ffffff;
  } */
  .login-label {
    width: 60%;
  }
  .login-input {
    width: 100%;
  }
  .login-form ::-webkit-input-placeholder {
    font-size: 14px;
  }
  .form-container .h3-grey {
    text-align: left !important;
  }
  .form-container .span-grey {
    text-align: left !important;
  }
  /* .login-additions {
    color: #ffffff;
    font-size: 12px;
  }
  .login-additions a {
    color: #ffffff;
  } */
  .span-black-400 {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
  /* .font-11 {
    color: #ffffff;
  }
  .h3-grey {
    color: #ffffff;
  } */
  .font-14 {
    font-size: 14px;
  }
  .grey-28 {
    font-size: 24px;
  }
  .carousel-container {
    width: 100%;
  }
  .form-container-xl {
    width: 100%;
  }
  .font-32 {
    font-size: 24px;
  }
  .font-32-500 {
    font-size: 20px;
  }
  .blue-check {
    height: 50px;
    width: 50px;
  }
  .congratulations {
    margin-top: 15%;
  }
  /* .login-form .form-select {
    background: none;
    border-bottom: 3px solid #ffffff;
    color: #fff;
  } */
  /* .blue-span {
    color: #ffffff;
  }
  .form-container-lg .text-blue {
    color: #fff;
  } */
  .signup-left {
    display: none;
  }
  .login-left {
    display: none;
  }
  .reset-left {
    display: none;
  }
  .create-left {
    display: none;
  }
  .setup-left {
    display: none;
  }
  .setupTutor-left {
    display: none;
  }
  .signup-header {
    text-align: center;
  }
  .login-header {
    text-align: center;
  }
  .create-header {
    text-align: center;
  }
  .congratulations svg {
    height: 100px !important;
  }
}