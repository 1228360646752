.admin-sidebar {
  background: #0a3066;
  position: fixed;
  min-height: 100vh;
  width: 16%;
}
.admin-sidebar .navactive {
  color: #044aac !important;
  background-color: #fff;
  width: 100%;
}
.admin-sidebar .navactive .nav-item {
  color: #044aac;
}
.admin-sidebar .navactive:hover {
  color: #044aac;
}
.admin-sidebar .inactive {
  color: #fff;
  background-color: transparent;
  width: 100%;
}
.admin-sidebar .inactive .nav-item:hover {
  color: #044aac;
}
.admin-sidebar .inactive:hover {
  color: #044aac;
  background-color: #fff;
}
.admin-sidebar .nav-item-active .nav-link {
  color: #fff !important;
}
.admin-sidebar .nav-item {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  width: 100%;
  padding: 0 10px !important;
  height: 53px;
  align-items: center;
  color: #ffff;
}
.resource-input {
  border: none;
  border-bottom: 1px solid rgba(51, 51, 51, 0.5);
  width: 100%;
  outline: none;
}
.resource-head {
  background: #0a3066;
  color: #fff;
  padding: 20px 40px !important;
}
.resource-edit {
  border: none;
  outline: none;
  background: none;
  color: #044aac;
}
.resource-blue {
  border-radius: 8px;
  background: rgba(0, 102, 255, 0.49);
  display: flex;
  align-items: center;
}
.table-row {
  cursor: pointer;
}
.student-img i {
  font-size: 10rem !important;
}
.student-img-admin img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.finance-card {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
}

.revenue-chart-container {
  border-radius: 0px 0px 6px 6px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.revenue-chart-header {
  border-radius: 6px 6px 0px 0px;
  background: var(--White, #fff);
  margin-bottom: 2px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.total-revenue-value {
  color: #0b1354;
}
.back-btn {
  border: none;
  background: none;
}
.post-select {
  padding: 8px 12px;
  border: 1px solid #044aac;
  border-radius: 10px;
  outline: none;
  color: #044aac;
}
.new-post-container {
  border: 1px solid grey;
  width: 60%;
  border-radius: 5px;
}
.new-post-input {
  font-family: Viga;
  color: #044aac !important;
  border: none;
  outline: none;
}
.save-draft-btn {
  border: 1px solid #044aac;
  color: #044aac;
  padding: 8px 30px;
  background: none;
  border-radius: 15px;
  font-size: 400;
  font-family: viga;
}
.posts-highlight-container {
  display: flex;
  gap: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 10px;
  -webkit-overflow-scrolling: touch;
}
.posts-highlight-item {
  border: none;
  border-top: 1px solid white;
  background: #fff;
  border-radius: 11px;
  box-shadow: 0px 7px 14px 0px #d3dae2;
  padding: 10px;
  margin: 16px;

  /* white-space: wrap; */
}

.each-announcement {
  width: fit-content;
  background: #e9e6e6b0;
  border-radius: 11px;
  flex-shrink: 0;
}

@media screen and (max-width: 497px) {
  .page-details .lato-24-700 {
    font-size: 14px;
  }
  .page-details .lato-24-400 {
    font-size: 14px;
  }
  .page-details .viga-24-400 {
    font-size: 18px;
  }
  .admin-header .viga-24-400 {
    font-size: 19px;
  }
  .student-img i {
    font-size: 5rem !important;
  }
  .each-announcement {
    width: 300px;
  }
}
